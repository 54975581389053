import React from "react";
import "./PoliciesBody.css";

const PoliciesBody = () => {
  return (
    <div className="mt-5 mb-5 container policies-body">
      <h4>POLÍTICA DE CALIDAD</h4>
      <p>Nos caracterizamos por prestar servicios de evaluación y certificación aplicando en todo momento los principios de Imparcialidad, Confidencialidad e Igualdad enmarcados en nuestra Misión, Visión y Objetivos de calidad. Implementamos prácticas de mejoramiento continuo en los diferentes procesos de nuestro sistema de gestión bajo la premisa de calidad total mediante el desarrollo de actividades de Revisión y Validación de esquemas, Reafirmación de exámenes, Verificación de métodos de evaluación y Formación continua para nuestros colaboradores. Propendemos por cumplir las expectativas sin favoritismos de todos los grupos de interés utilizando los recursos adecuados, tecnología de vanguardia y personal cualificado.</p>
      <p>Nuestro principal compromiso es contribuir con la competitividad y productividad de nuestro país a través de la ejecución de procesos de certificación acordes a las exigencias del ámbito laboral, normatividad vigente y tendencias del mercado actual. Si una persona recibe un certificado de conformidad es porque al momento de aplicar el esquema demostró bajo evidencia objetiva la competencia requerida, en función a los criterios de conformidad, para desempeñarse de forma eficaz en el sector productivo según la categoría de certificación.</p>
      
      <h4>POLÍTICA DE IMPARCIALIDAD</h4>
      <p>AIEX S.A.S. ha asumido el compromiso de asegurar en todos los niveles organizacionales la integridad del proceso de evaluación y certificación de personas, realizando análisis exhaustivos a las situaciones que pueden generar conflictos de interés y asumiendo la responsabilidad absoluta por las actuaciones del personal interno y subcontratado que participa directa e indirectamente en la aplicación de esquemas de certificación. Para cada grupo de interés se han determinado las actividades, responsables, documentos y demás aspectos que certifican una gestión ecuánime y justa sin que prevalezcan intereses en particular ni permitir presiones comerciales, financieras o de otra índole que puedan afectar el normal desarrollo del objeto social.</p>
      <p>Hemos establecido procedimientos para identificar y gestionar las amenazas a la imparcialidad derivadas de las actividades de evaluación y certificación, así como de las relaciones con otros organismos. En estos documentos se definen las actividades a desarrollar con el fin de minimizar y/o eliminar este tipo de riesgos teniendo en cuenta todas las fuentes potenciales que pueden afectar la independencia en la emisión de juicios de conformidad y toma de decisiones de certificación. Tenemos en cuenta los aportes de los representantes de partes interesadas para aplicarlas en nuestras actuaciones y procesos.</p>
      
      <h4>POLÍTICA DE CONFIDENCIALIDAD (TRATAMIENTO DE LA INFORMACIÓN)</h4>
      <p>Protegemos íntegramente toda la información a la cual tenemos acceso sobre solicitantes, candidatos, personas certificadas y de terceros en el marco de las actividades de certificación. </p>
      <p>Respetamos la privacidad y naturaleza confidencial de la información personal de quienes intervienen en la prestación del servicio bien sea de origen interno o externo. En este sentido, sólo conservamos datos en la medida en que ésta sea necesaria para demostrar el estado de una persona evaluada y/o certificada, para efectos de trazabilidad del proceso de evaluación y asegurar el desarrollo efectivo de las actividades de certificación, o bien por exigencias de ley. Así mismo, no se divulgará información a una parte no autorizada sin el consentimiento escrito del individuo, excepto cuando la ley requiera la anunciación de tal información.</p>
      <p>El personal interno y subcontratado que interviene en la aplicación de esquemas de certificación ha suscrito acuerdos ejecutables que garantizan la gestión adecuada de la información confidencial resultante del proceso y tienen instrucciones claras para no divulgar o discutir aspectos delicados sobre el desempeño de las personas, resultados de evaluaciones o cualquier otro dato resultante de la prestación del servicio así como de aspectos que tienen que ver con la situación administrativa y financiera del organismo. Esta responsabilidad continúa incluso después de terminada la vinculación contractual.</p>
      <p>Finalmente, AIEX S.A.S. se compromete a tomar medidas para asegurar que las actividades de los organismos relacionados no comprometen la confidencialidad de la información.</p>

      <h4>POLÍTICA DE IGUALDAD</h4>
      <p>No se restringirá el acceso al proceso de certificación a una persona por razones que no tengan que ver con los lineamientos establecidos en cada esquema de certificación, no impediremos la prestación de los servicios por razones que no estén relacionados únicamente con el cumplimiento de los prerrequisitos definidos para la solicitud. Una vez registrados, todos los candidatos tendrán las mismas oportunidades de éxito y serán evaluados de forma equitativa, justa y ecuánime tal y como lo especifican los protocolos internos desarrollados, sin preferencias ni distinciones inaceptables. </p>
      <p>Se eliminarán al máximo los sesgos en la evaluación a través de la parametrización de los sistemas operativos utilizados para el registro de la información, las actuaciones del personal del organismo serán monitoreadas desde la alta dirección, y cualquier indicio de divergencia entre candidatos será tratado bajo evidencia objetiva, agotando todas las instancias, buscando siempre equiparar los derechos y deberes de solicitantes, candidatos y personas certificadas.</p>

      <h4>POLÍTICA DE SUSPENSIÓN Y RETIRO DE LA CERTIFICACIÓN</h4>
      <p>Las actuaciones del personal del organismo están encaminadas a infundir confianza a nuestros clientes y partes interesadas con relación a los procesos de evaluación y certificación. Por tal motivo, la certificación otorgada es susceptible de ser suspendida durante un período de tiempo establecido por el organismo o retirada definitivamente cuando se evidencie que la persona ha hecho uso indebido o ilegal del Certificado de conformidad o se han incumplido las condiciones para su utilización.</p>
      <p>Las investigaciones y decisiones en relación a suspender o retirar una certificación cumplirán con el principio de Imparcialidad y serán resueltas de manera objetiva teniendo en cuenta lo establecido en procedimientos internos cumpliendo con todas las instancias de resolución.</p>

      <h4>POLÍTICA DE QUEJAS Y APELACIONES</h4>
      <p>Daremos tratamiento a todas las quejas y apelaciones que surjan del proceso de evaluación y certificación, asegurando que las decisiones adoptadas para su gestión sean justas, equitativas y neutrales. Para tal efecto, en cada etapa de recepción, validación, investigación y decisión sobre las solicitudes recibidas en torno al proceso de certificación, las personas designadas a dar trámite a las mismas serán autónomas y aplicarán los principios de Igualdad e Imparcialidad garantizando respuestas y soluciones oportunas sin dar lugar a acciones discriminatorias contra quien interpone la solicitud.</p>
      <p>Nos comprometemos a publicar a la vista de todo el público el procedimiento definido para el tratamiento de quejas y apelaciones, así como a mantener en estricta Confidencialidad toda la información derivada de cada trámite.</p>
      <p>Asumimos toda la responsabilidad derivada de las decisiones tomadas en función a la resolución de quejas y apelaciones, las cuales serán notificadas personalmente a quien la interpuso.</p>

      <h4>POLÍTICA DE SEGURIDAD</h4>
      <p>Hemos establecido los mecanismos para salvaguardar los materiales de examen desde su ubicación, naturaleza y amenazas resultantes de uso repetido, por medio de espacios seguros con condiciones de almacenamiento, distribución, traslado y disposición final acordes a las necesidades y requerimientos de orden normativo y legal.</p>
      <p>Aplicamos procedimientos específicos en los que se identifican las actividades y responsables para extraer a intervalos planificados las copias de seguridad de la información del esquema. También hemos suscrito una póliza de responsabilidad civil extracontractual para cubrir los daños y perjuicios que a terceros pueda ocasionar el organismo al desarrollar las actividades de evaluación y certificación.</p>
      <p>Durante la aplicación de los exámenes bien sea directamente o a través del uso de Tecnologías de la Información y Comunicaciones, evitamos las tentativas de fraude por medio de la firma de un documento contractual con los solicitantes con el fin de salvaguardar la información y los materiales, delegando a un examinador/supervisor para inspeccionar cada proceso de examen e impidiendo el uso ayudas no autorizadas al momento de ejecutar cada una de las pruebas. </p>

      <h4>POLÍTICA DE PRECIOS</h4>
      <p>Ofrecemos nuestros servicios de evaluación y certificación a precios justos y bajo la consideración de que el costo no se convierta en un impedimento para la certificación adhiriéndonos a las condiciones de oferta y demanda del mercado.</p>
      <p>Las tarifas de los servicios de evaluación y certificación están publicadas a la vista de todo el público para evitar confusiones, ambigüedades y tentativas de fraude durante la aplicación del esquema de certificación, así como para cuando se soliciten procesos de vigilancia y/o renovación de la certificación, duplicados del certificado en caso de pérdida o deterioro (Caso en el cual la persona certificada deberá aportar la respectiva denuncia o el físico deteriorado) y las demás actividades consideradas para la prestación del servicio. </p>

      <h4>POLÍTICA DE GESTIÓN DE RIESGOS</h4>
      <p>Como parte de nuestra cultura organizacional, se establecen los elementos y el marco general de actuación para gestionar integralmente los riesgos de toda naturaleza a los que se enfrenta el organismo; este enfoque hace parte del Sistema de Gestión de la Calidad implementado y se complementa con la participación activa de los Dueños de Procesos mediante el compromiso adquirido desde la alta dirección para establecer directrices específicas de mitigación que contribuyan al cumplimiento de nuestra Misión y Visión. </p>
      <p>Esta política es de aplicación en todos los niveles organizacionales con el fin de prever situaciones y amenazas que puedan afectar los procedimientos, metas y actividades desarrolladas, por lo que se gestionan a través del establecimiento y puesta en marcha de Acciones Preventivas, en las que se definen las actividades para prevenir y mitigar, desde la proactividad, situaciones futuras de cualquier índole relacionadas con el objeto social. </p>
      <p>De manera general, se considera un riesgo cualquier amenaza de que un evento, acción u omisión pueda impedir al organismo lograr los objetivos y ejecutar sus estrategias con éxito. </p>
      <p>Enfoque basado en riesgos es la implementación sistemática de un conjunto de acciones tendientes al manejo óptimo de situaciones que puedan afectar positiva o negativamente la estrategia corporativa, los procesos del sistema de gestión, los proyectos o nuevas inversiones y en general todos los aspectos del organismo de tal forma que pueda garantizarse razonablemente el logro de los objetivos de calidad. El ciclo de la gestión de riesgos comprende actividades de identificación de causas, correcciones inmediatas y el establecimiento de planes de acción, así como las acciones de seguimiento para administrarlos.</p>
    </div>
  );
};

export { PoliciesBody };